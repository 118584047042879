import React from 'react';
import SEO from '../components/seo';

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      404
    </>
  );
}

export default NotFoundPage;
